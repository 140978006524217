import styled from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants"

export const StyledSectionTitle = styled.h2`
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 3.438rem;
    color: #ffffff;
    
    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        font-size: 2rem;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        font-size: 1.5rem;
    }
`;

export const StyledSectionDescription = styled.p`
    color: rgba(255, 255, 255, 0.60);
    font-family: Nunito Sans;
    font-size: 1rem;
    line-height: 150%;
    letter-spacing: 0.8px;
`;

export const StyledMarginWrapper = styled.div`
    margin: 7.5rem 0;
`;
