import React from "react";
import BenefitsFlippableListSection from "@common/CommonSections/BenefitsFlippableListSection";
import { StyledMarginWrapper } from "../../common-styles";
import { FLIPPABLE_BENEFITS_SECTION_TITLE, WHY_CHOOSE_ITEMS } from "../../constants";

const FlippableBenefits = () => {
    return (
        <StyledMarginWrapper>
            <BenefitsFlippableListSection heading={FLIPPABLE_BENEFITS_SECTION_TITLE} configuration={WHY_CHOOSE_ITEMS} />
        </StyledMarginWrapper>
    )
}

export default FlippableBenefits;